<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Employee') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="hiddenColTable"
                            :items="employees"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoadingEmployee"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-dialog v-model="dialogCU" persistent max-width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" dark class="mb-2" @click="onAddNewItem()" small>
                                                <v-icon small> mdi-plus </v-icon>
                                                {{ $t('cms.add_new') }}
                                            </v-btn>
                                        </template>

                                        <EmployeeModal
                                            :cuItem="cuItem"
                                            :formTitle="formTitle"
                                            :closeCU="closeCU"
                                            :save="onSaveItem"
                                            :propsField="propsField"
                                            :columns="tableColumns"
                                            :dialogEdit="dialogEdit"
                                            v-if="dialogCU"
                                        >
                                        </EmployeeModal>
                                    </v-dialog>

                                    <v-spacer></v-spacer>

                                    <div class="search">
                                        <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select>
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>

                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete :closeDelete="closeDelete" :deleteItemConfirm="deleteItemConfirm">
                                        </ModalDelete>
                                    </v-dialog>
                                    <v-dialog v-model="dialogNoti" max-width="500px">
                                        <ModalNoti :close="closeNoti" :message="formMessage"></ModalNoti>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.avatar`]="{ item }">
                                <div class="my-2">
                                    <v-img :src="item.avatar || noImage" alt="" class="table-image" />
                                </div>
                            </template>
                            <template v-slot:[`item.full_name`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.full_name }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.updated_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.updated_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:item.record="{ item }">
                                <router-link :to="{ path: `/courses-of-employee/${item.id}` }">
                                    <v-btn color="indigo" dark small>
                                        <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                        Record
                                    </v-btn>
                                </router-link>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn color="info" class="mr-2" small @click="onUpdateItem(item)">
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn
                                    color="error"
                                    dark
                                    class="mr-2"
                                    small
                                    @click="onDeleteItem(item)"
                                    v-if="item.id !== userInfo.id"
                                >
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <f-pagination
                                v-model="page"
                                :pageSize="employeePagination.pageSize"
                                :totalItems="employeePagination.totalItems"
                                :disabled="isLoadingEmployee"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24"></a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EmployeeModal from '../../components/CMS/Modal/Employee.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS/Modal/Noti.vue';
import { CRUD } from '../../constants';
import { mixinFormats, mixinToasts, mixinLoadings } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinFormats, mixinToasts, mixinLoadings],
    components: { EmployeeModal, ModalDelete, ModalNoti },

    data() {
        return {
            tableColumns: [
                { text: this.$t('form.image'), value: 'avatar', width: '100px', sortable: false },
                { text: this.$t('form.full_name'), value: 'full_name', sortable: false, width: '200px' },
                { text: this.$t('form.email'), value: 'email', sortable: false, width: '200px' },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                {
                    text: this.$t('form.updated_at'),
                    value: 'updated_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '210px' },
            ],
            fieldsSearch: [
                {
                    title: this.$t('form.full_name'),
                    value: 'full_name',
                },
                {
                    title: this.$t('form.email'),
                    value: 'email',
                },
            ],
            selectedField: 'full_name',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            cuItemDelete: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
            userInfo: this.$store.getters.getUserInfo,
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('employee', ['isLoadingEmployee', 'employees', 'employeePagination']),
        formTitle() {
            return this.cuIndex === -1 ? this.$t('cms.new_item') : this.$t('cms.edit_item');
        },
        hiddenColTable() {
            return this.tableColumns.filter((s) => s.value !== 'password');
        },
    },
    async mounted() {
        await this.getEmployeeList({ page: this.page, field: this.selectedField, search: this.search });
    },
    watch: {
        page(val) {
            this.getEmployeeList({ page: val, field: this.selectedField, search: this.search });
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
    },
    methods: {
        ...mapActions('employee', ['getEmployees', 'postNewEmployee', 'putOneEmployee', 'deleteOneEmployee']),
        async getEmployeeList(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field;
            this.search = data?.search || '';
            await this.getEmployees(data);
        },

        initialize() {
            this.getEmployeeList({ page: 1, field: 'full_name', search: '' });
        },
        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },
        onAddNewItem() {
            this.dialogEdit = false;
            this.dialogCU = true;
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.employees.indexOf(item);
            this.cuItem = Object.assign({}, item);
        },
        onDeleteItem(item) {
            this.cuIndex = this.employees.indexOf(item);
            this.cuItemDelete = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            this.closeDelete();
            this.showLoading();
            const res = await this.deleteOneEmployee(this.cuItemDelete.id);
            if (res?.isSuccess) {
                await this.getEmployeeList({ page: this.page, field: this.selectedField, search: this.search });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.data);
            }
            this.hideLoading();
        },
        closeCU() {
            this.dialogCU = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItemDelete = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeNoti() {
            this.dialogNoti = false;
        },
        async onSaveItem(callback) {
            this.showLoading();
            if (this.cuIndex === -1) {
                const res = await this.postNewEmployee(this.cuItem);
                callback(res?.isSuccess);
                if (res?.isSuccess) {
                    await this.getEmployeeList({ page: 1, field: 'full_name', search: '' });
                    this.showSuccess(CRUD.CREATE);
                } else {
                    this.showError(res?.data);
                }
            } else {
                const res = await this.putOneEmployee(this.cuItem);
                callback(res?.isSuccess);
                if (res?.isSuccess) {
                    await this.getEmployeeList({ page: this.page, field: this.selectedField, search: this.search });
                    const IDBossStore = this.$store.getters.getUserInfo.id;
                    if (res.data.id === IDBossStore) {
                        this.$store.dispatch('updateInfo', res?.data);
                    }
                    this.showSuccess(CRUD.UPDATE);
                } else {
                    this.showError(res?.data);
                }
            }
            this.hideLoading();
        },
        onSearchItem: _.debounce(function (e) {
            this.getEmployeeList({ page: 1, field: this.selectedField, search: this.search });
        }, 500),
        handleChangeSelect(value) {
            this.getEmployeeList({ page: 1, field: value, search: this.search });
        },
    },
};
</script>

<style lang="scss">
.table-image {
    width: 45px;
    height: 45px;
}

.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}
</style>
